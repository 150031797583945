<template>
  <div class="index">
    <van-search
        v-model="value"
        show-action
        placeholder="搜索欢迎语名称"
        shape="round"
        @search="onSearch"
    >
      <template #action >
        <div @click="routerPush('/contactWelcome/create')" style="    display: flex;
    align-items: center; color:#2b60dd;font-size: 26px;">
          <van-icon style="font-size: 26px" name="add" />
        </div>
      </template>
    </van-search>

    <div class="content">
      <van-divider style="margin: -1px 0px;" />
      <van-tabs v-model="active" color="#1890ff" title-active-color="#1890ff">
        <van-tab title="我的欢迎语">
          <div class="item-list">
            <div v-if="greetingList.now" class="list-item">
                <div  style="    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;">
                  {{ greetingList.now.words }}
                </div>
                   <div style="    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;">使用成员：
                     <span v-for="(it,ins) in greetingList.now.employees" :key="ins">
                       <img style="    width: 18px;
    height: 18px;
    vertical-align: middle;" :src="it.avatar" alt="">
                        {{it.name}}
                   </span></div>
                <div>{{ greetingList.now.createdAt }}</div>
              <div class="button">
                <div @click="remove(greetingList.now.id)">
                  <van-icon name="delete-o" />
                  删除
                </div>
                <div>
                  <van-icon @click="edit(greetingList.now.id)" name="edit" />
                  编辑
                </div>
              </div>
            </div>
            <van-empty
                v-else
                class="custom-image"
                :image="require('../../assets/images/welcome-empty.png')"
                description="你还没有欢迎语哦～"
            />
          </div>
        </van-tab>
        <van-tab title="历史欢迎语">
          <div class="item-list">
            <div v-if="greetingList.history.length > 0">
              <div v-for="(item, index) in greetingList.history" class="list-item">
                <div style="    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;">
                  {{item.words}}
                </div>
                <div style="    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;">使用成员：
                  <span v-for="(it,ins) in item.employees" :key="ins">
                       <img style="    width: 18px;
    height: 18px;
    vertical-align: middle;" :src="it.avatar" alt="">
                        {{it.name}}
                   </span></div>
                <div>{{ item.createdAt  }}</div>
                <div class="button" @click="remove(item.id)">
                  <div>
                    <van-icon name="delete-o" />
                    删除
                  </div>
                  <div @click="edit(item.id)">
                    <van-icon name="edit" />
                    编辑
                  </div>
                </div>
              </div>
            </div>
            <van-empty
                v-else
                class="custom-image"
                :image="require('../../assets/images/welcome-empty.png')"
                description="你还没有欢迎语哦～"
            />
          </div>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import {destroy, greetingL} from "../../api/contactWelcome";

export default {
  data() {
    return {
      // 文字
      message: '',
      // tbas切换
      value:"",
      active: 0,
      greetingList: {
        history:[]
      }

    }
  },
  watch: {},
  created() {
    this.greetingData()
  },
  methods: {
    onClickLeft() {
      this.$router.back()
    },
    // 跳转
    routerPush (e){
      this.$router.push(e)
    },
    // 搜素
    onSearch(){
      this.greetingData(this.value)
    },
    // 欢迎页列表
    greetingData(name){
      greetingL({name}).then(res=>{
        this.greetingList = res.data
      })
    },

    remove(id){
      destroy({greetingId:id}).then(res=>{
        this.$message.success('删除成功')
        this.greetingData()
      })
    },
    edit(id){
      this.$router.push({
        path: '/contactWelcome/create',
        query: {
          id: id
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
/deep/.van-tabs__line{
  bottom: 9px;
  margin-top: 0;
}

/deep/ .van-tabs__nav--line {
  padding-bottom: 1px;
}

/deep/ .van-tab__text {
  margin-bottom: 10px;
}

/deep/ .van-tab {
  background: #ffffff;
}

/deep/ .van-tabs__nav {
  background: #F1f2f5;
}

/deep/ .van-search__content{
  background: #f2f4f8;
}

/deep/.van-empty__image img{
  object-fit: contain;
}
.index {
  font-size: 14px;
  height: 100%;
  background: #fff;

  p {
    margin: 0 !important;
  }

  .fs17 {
    font-size: 17px;
  }

  .fs14 {
    font-size: .14px;
  }

  .content {
    background: #f2f4f8;
    height: 92%;
    //padding-top: 5px;

    img {
      width: 22px;
      height: 22px;
    }

    .mgt16 {
      margin-top: 8px;
    }

    .basecard {
      background-color: #fff;
      padding: 0 0 24px 0;

      .header {
        height: 65px;
        padding: 0 15px 0 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .header__title {
          line-height: 21px;
          font-weight: 500;
          color: #000;
        }

        .header__right {
          flex: 1;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .svg-icon {
            vertical-align: -0.15em;
            fill: currentColor;
            overflow: hidden
          }

          .switch {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .switch__item--active {
              font-weight: 500;
              color: #000;
            }

            .switch__item {

              padding: 0 3px;
              margin-left: 7px;
              color: #8c8c8c;
              font-size: 12px;
            }
          }
        }

      }

      .databox {
        padding: 0 16px;

        .list {
          border-radius: 15px;
          padding: 36px 0;
          overflow: hidden;
          display: flex;
          justify-content: space-around;
          align-items: center;
          flex-wrap: wrap;
          padding-top: 30px;
          padding-bottom: 30px;

          .list__item__desc {
            font-size: 11px;
            line-height: 16px;
            color: #545454;
          }

          .list__item__count {
            font-size: 20px;
            line-height: 21px;
            margin-bottom: 4px;
            font-weight: 500;
            color: #000;
            text-shadow: 0 3px 6px rgb(0 0 0 / 16%);
          }

          .mgb40 {
            margin-bottom: 20px;
          }

          .list__item {
            width: 30%;
            text-align: center;
          }
        }

        .customer {

        }
      }

      .chart__box {
        position: relative;
        height: 250px;

        .charts__tips {
          font-size: 10px;
          padding: 5px 16px 0;
          text-align: right;
          color: #b2b2b2;
        }
      }

      .content {
        padding: 0 16px;
        background-color: #fff;

        .rank {
          .header {
            position: relative;
            padding: 18px 28px 18px 26px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            line-height: 24px;
            font-weight: 500;
            border-radius: 15px 15px 0 0;
            color: #fff;
            background-color: #294ba3;

            &:after {
              content: " ";
              width: 0;
              height: 0;
              border-left: 5px solid transparent;
              border-right: 5px solid transparent;
              border-bottom: 8px solid #fff;
              position: absolute;
              bottom: 0;
              left: 79px;
            }

            .header__title {
              line-height: 0.56rem;
              font-weight: 500;
              color: #fff;
            }

            .header__time {
              padding-left: 32px;
              position: relative;

              &:after {
                border-left-width: 1px !important;
                position: absolute;
                box-sizing: border-box;
                content: " ";
                pointer-events: none;
                top: -50%;
                right: -50%;
                bottom: -50%;
                left: -50%;
                border: 0 solid #f2f4f8;
                -webkit-transform: scale(.5) !important;
                transform: scale(.5);
              }
            }
          }
        }
      }
    }

    .item-list{
      padding: 10px 15px;
      .list-item{
        background: #Fff;
        border-radius: 14px;
        padding: 10px 16px;
        position: relative;
        margin-bottom: 10px;
        div:nth-child(1){
          color: #444;
          font-size: 15px;
          margin: 3px 0;
          span{
            font-size: 12px;
            background: #3975c6;
            color: #fff;
            padding: 2px 3px;
            border-radius: 5px;
            margin-left: 7px;
          }
        }
        div:nth-child(2){
          color: #686868;
          margin: 10px 0;
        }
        div:nth-child(3){
          color: #969696;
          font-size: 12px;
        }
        .button{
          display: flex;
          align-items: center;
          position: absolute;
          right: 9px;
          /* top: 0; */
          bottom: 0;
          /* height: auto; */
          font-size: 12px;
          justify-content: space-between;
          width: 86px;
          div:nth-child(1){
            font-size: 12px;
          }
        }
      }
    }
  }
}
</style>
